import React from 'react';
import { useState } from 'react';

import './App.css';
import Grid from './Grid';

//<Textbox numberOfTextBoxes={count}></Textbox>



function App() {
  
  const [gridItems, setGridItems] = useState(Array(1).fill(null));
  const [count, incrementCount] = useState(2)
  
  
return (
  <div>
    
    <Grid setGridItems={setGridItems} gridItems={gridItems} ></Grid>

  </div>

  );
}

export default App;
