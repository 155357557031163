import React from 'react';
import './App.css'; // You can create a CSS file for styling

/*
PascalCase
camelCase
snake_case
kebab-case
*/

const gridContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
};


const gridItem = {

    
    color: 'black',
    backgroundColor: 'aqua',
    
    flex: 1,
    fontSize: 'large',
    textAlign: 'left',
    
    
    minWidth: '150px',
    minHeight: '150px',
    
    maxWidth: '150px',
    maxHeight: '150px',
    
    margin: '20px',
    padding: '10px',
    paddingLeft: '20px',
    
    border: '3px solid black',  
    borderRadius: '20px',

    listStyle: 'none',
    
    boxShadow: '-8px 8px 10px -4px black' /* x-offset y-offset blur spread color*/
}
const AddItemButton = {

    
    color: 'black',
    backgroundColor: 'white',
    
    flex: 1,
    fontSize: 'large',
    textAlign: 'center',
    
    
    minWidth: '80px',
    minHeight: '80px',
    
    maxWidth: '80px',
    maxHeight: '80px',
    
    margin: '20px',
    marginLeft: '100px',
    padding: '10px',
    
    border: '3px solid black',  
    borderRadius: '40px',

    listStyle: 'none',
    
    boxShadow: '-8px 8px 10px -4px black' /* x-offset y-offset blur spread color*/
}

function Grid(props) {
  
    const addGridItem = () => {
      const newItem = `Textbox ${props.gridItems.length + 1}`;
      props.setGridItems([...props.gridItems, newItem]);
    };
   
    const resetGridItems = () => {
      props.setGridItems([1]);
    };
  
    return (
      <div>
        <button style={AddItemButton} onClick={addGridItem}>+</button>
        <button style={AddItemButton} onClick={resetGridItems}>-</button>
        <div style={gridContainer}>
          {props.gridItems.map((item, index) => (
            <div style={gridItem} key={index}>{item}</div>
          ))}
        </div>
      </div>
    );
  }


export default Grid;
